import { FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, RegisterOptions, SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useTheme } from '@mui/material/styles'
import { addDays, differenceInCalendarDays, differenceInCalendarMonths, getHours, isSameDay, isValid } from 'date-fns'

import ControlDropdown from '@Components/container/form/dropdown/ControlDropdown'
import ControlInputDate from '@Components/container/form/inputDate/ControlInputDate'
import { LargeDown, LargeUp } from '@Components/utils/media/Responsive'
import { DEFAULT_TIME, MIN_DISPLAYED_TIME } from '@DS/components/components/datePicker/DatePicker.constants'
import { InitDate } from '@DS/components/components/datePicker/DatePicker.types'
import BottomActions from '@DS/components/forms/button/bottomActions/BottomActions'
import Button from '@DS/components/forms/button/Button'
import { DropdownOption } from '@DS/components/forms/dropdown/Dropdown.types'
import Separator from '@DS/components/foundations/shapes/separator/Separator'
import { useIsInModal } from '@DS/components/navigation/modal/ModalContext'
import ScrollableContent from '@DS/components/navigation/modal/ScrollableContent'
import ConditionalWrapper from '@DS/components/utils/wrapper/ConditionalWrapper'
import { dateToIsoDate } from '@Utils/date'
import {
  generateHours,
  getDateHourWithDefault,
  getDefaultMaxDate,
  isDateOutsideRestrictedList,
  transformDate,
} from '@Utils/datepicker'

import ControlDatePicker, { ControlDatePickerProps } from './_internal/ControlDatePicker'
import { LongDistanceDatePickerFormData } from './LongDistanceDatePicker.types'
import * as styles from './styles'

export type LongDistanceDatePickerFormProps = Pick<
  ControlDatePickerProps,
  'initTarget' | 'maxDate' | 'mode' | 'restrictedDatesList'
> & {
  initEndDate?: Omit<InitDate, 'date'> & Partial<Pick<InitDate, 'date'>>
  initStartDate?: InitDate
  isInwardDateVisible?: boolean
  isOutwardDateVisible?: boolean
  hasTimeHidden?: boolean
  onSubmit: SubmitHandler<LongDistanceDatePickerFormData>
}

const LongDistanceDatePickerForm: FC<LongDistanceDatePickerFormProps> = ({
  initEndDate,
  initStartDate,
  initTarget,
  isInwardDateVisible = false,
  isOutwardDateVisible = false,
  hasTimeHidden = false,
  maxDate = getDefaultMaxDate(),
  mode,
  onSubmit,
  restrictedDatesList,
}) => {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const isInModal = useIsInModal()

  const scrollElementRef = useRef<HTMLDivElement>(null)

  const generateStartDateHours = (startDate: Date) => {
    const today = new Date()
    const todayHours = getHours(today)
    const todayMinTime = todayHours - (todayHours % 2)

    return generateHours(isSameDay(startDate, today) ? todayMinTime : MIN_DISPLAYED_TIME)
  }

  const [startHoursOptions, setStartHoursOptions] = useState(
    initStartDate?.date ? generateStartDateHours(initStartDate.date) : []
  )
  const [endHoursOptions, setEndHoursOptions] = useState<DropdownOption[]>([])
  const [showStartDateHour, setShowStartDateHour] = useState(!!initStartDate?.date)
  const [showEndDateHour, setShowEndDateHour] = useState(!!initEndDate?.date)

  const { isDateMandatory: isStartDateMandatory = true, inputDateLabel: startDateLabel } = initStartDate || {}

  const methods = useForm<LongDistanceDatePickerFormData>({
    defaultValues: {
      startDate: dateToIsoDate(initStartDate?.date),
      endDate: dateToIsoDate(initEndDate?.date),
      startDateTime: initStartDate?.date ? getDateHourWithDefault(initStartDate.date) : '',
      endDateTime: initEndDate?.date ? getDateHourWithDefault(initEndDate.date) : '',
    },
    mode: 'onChange',
  })

  const { control, handleSubmit, setValue, getValues, clearErrors, trigger, watch } = methods

  const generateEndDateHours = useCallback(
    (startDate: Date, endDate: Date) => {
      const startDateTime = getValues('startDateTime')

      return generateHours(isSameDay(startDate, endDate) ? +startDateTime + 2 : MIN_DISPLAYED_TIME)
    },
    [getValues]
  )

  const calculateStartDateTime = (startDate: Date, shouldResetTime?: boolean) => {
    const startDateTime = getValues('startDateTime')

    const today = new Date()
    const initTime = shouldResetTime ? DEFAULT_TIME : +startDateTime
    const newStartDateHours = isSameDay(startDate, today)
      ? Math.max(getHours(today) - (getHours(today) % 2), initTime)
      : initTime
    setStartHoursOptions(generateStartDateHours(startDate))
    setValue('startDateTime', `${newStartDateHours}`)
  }

  const calculateEndDateTime = useCallback(
    (endDate: Date) => {
      const [startDate, startDateTime, endDateTime] = getValues(['startDate', 'startDateTime', 'endDateTime'])

      if (!startDate) {
        return
      }

      const formattedStartDate = transformDate(startDate)
      const today = new Date()
      const minEndDate = differenceInCalendarDays(today, formattedStartDate) < 0 ? formattedStartDate : today
      let newEndDate = endDate
      let newEndDateHours = +endDateTime || DEFAULT_TIME

      if (isSameDay(minEndDate, endDate)) {
        if (+startDateTime === 22) {
          newEndDate = addDays(endDate, 1)
        } else {
          newEndDateHours = +startDateTime + 2
        }
      }

      setValue('endDate', dateToIsoDate(newEndDate), { shouldValidate: false })
      setEndHoursOptions(generateEndDateHours(minEndDate, newEndDate))
      setValue('endDateTime', `${newEndDateHours}`)
    },
    [generateEndDateHours, getValues, setValue]
  )

  const startDateWatcher = watch('startDate')

  useEffect(() => {
    trigger('endDate')
  }, [trigger, startDateWatcher])

  const startDateTimeWatcher = watch('startDateTime')

  useEffect(() => {
    const endDate = getValues('endDate')

    if (endDate) {
      calculateEndDateTime(transformDate(endDate))
    }
  }, [calculateEndDateTime, getValues, startDateTimeWatcher])

  const isDatesChronologyOk = () => {
    const [startDate, endDate] = getValues(['startDate', 'endDate'])

    const formattedStartDate = transformDate(startDate)
    const formattedEndDate = transformDate(endDate)

    return (
      isValid(formattedStartDate) &&
      isValid(formattedEndDate) &&
      differenceInCalendarDays(formattedEndDate, formattedStartDate) >= 0
    )
  }

  const dateValidator = (date: string, isEndDate = false) => {
    const formatedDate = transformDate(date)
    const today = new Date()

    if (date === '' || (!isEndDate && mode === 'INWARD')) {
      return 'valid'
    }

    if (!isValid(formatedDate)) {
      return 'invalid'
    }

    if (isDateOutsideRestrictedList(formatedDate, restrictedDatesList)) {
      return 'unselectable'
    }

    if (differenceInCalendarDays(formatedDate, today) < 0) {
      return 'pastDate'
    }

    if (differenceInCalendarDays(formatedDate, maxDate) > 0) {
      return 'dateTooFar'
    }

    if (isEndDate && !isDatesChronologyOk()) {
      return 'chronologyError'
    }

    if (isEndDate && isValid(formatedDate)) {
      calculateEndDateTime(formatedDate)
    } else if (!isEndDate && isValid(formatedDate)) {
      calculateStartDateTime(formatedDate)
    }
    const [startDate, endDate] = getValues(['startDate', 'endDate'])
    setShowStartDateHour(isValid(transformDate(startDate)))
    setShowEndDateHour(isValid(transformDate(endDate)))

    if (isEndDate && !isSameDay(formatedDate, transformDate(startDate))) {
      trigger('startDate')
    }

    return 'valid'
  }

  const isEndDateRequired = !!initEndDate?.isDateMandatory || mode === 'INWARD'

  const datePickerMaxDate = mode === 'OUTWARD' && initEndDate?.date ? initEndDate.date : maxDate
  const today = new Date()
  const differenceMonths = differenceInCalendarMonths(datePickerMaxDate, today)

  const dateValidationRules = {
    valid: true,
    invalid: formatMessage({ id: 'datepicker_error_invalidDate' }),
    pastDate: formatMessage({ id: 'datepicker_error_pastDate' }),
    unselectable: formatMessage({ id: 'datepicker_error_unselectable' }),
    dateTooFar: formatMessage({ id: 'datepicker_error_dateTooFar' }, { month: differenceMonths }),
    chronologyError: formatMessage({ id: 'datepicker_error_chronologyError' }),
  }

  const startDateFormRules: RegisterOptions = {
    required: isStartDateMandatory && formatMessage({ id: 'datepicker_error_invalidDate' }),
    validate: (date: string) => dateValidationRules[dateValidator(date, false)],
  }

  const endDateFormRules: RegisterOptions = {
    required: isEndDateRequired && formatMessage({ id: 'datepicker_error_invalidDate' }),
    validate: (date: string) => dateValidationRules[dateValidator(date, true)],
  }

  useEffect(() => {
    if (mode === 'SINGLE_END_DATE' || mode === 'SINGLE_START_DATE') {
      setValue('startDate', initStartDate?.date ? dateToIsoDate(initStartDate.date) : '')
    }
  }, [setValue, initStartDate?.date, mode])

  const handleDatePickerChange = (selectedStartDate: Date, selectedEndDate?: Date) => {
    clearErrors()

    const [startDate, endDate] = getValues(['startDate', 'endDate'])

    if (!selectedEndDate && initEndDate?.isDateMandatory) {
      if (differenceInCalendarDays(transformDate(endDate), selectedStartDate) < 0) {
        const dayAfter = addDays(selectedStartDate, 1)
        setValue('endDate', dateToIsoDate(dayAfter), {
          shouldValidate: true,
        })
        calculateEndDateTime(dayAfter)
      }
    } else if (!selectedEndDate && !initEndDate?.isDateMandatory) {
      setValue('endDate', '')
      setValue('endDateTime', '')
    }

    if (!isSameDay(selectedStartDate, transformDate(startDate))) {
      setValue('startDate', dateToIsoDate(selectedStartDate), { shouldValidate: true })
      calculateStartDateTime(selectedStartDate, true)
    }

    if (mode === 'SINGLE_END_DATE') {
      setValue('startDate', dateToIsoDate(initStartDate?.date))
    }

    if (selectedEndDate) {
      calculateEndDateTime(selectedEndDate)
    }

    setShowEndDateHour(!!selectedEndDate)
  }

  const onClearEndDate = () => setShowEndDateHour(false)

  const getOptionLabel = (options: DropdownOption[], value: string) => options.find((opt) => opt.value === value)?.label

  const onSubmitHandler = (e: FormEvent) => {
    e.stopPropagation()

    return handleSubmit(onSubmit)(e)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmitHandler} css={styles.longDistanceDatePickerFormRoot}>
        <ConditionalWrapper
          condition={isInModal}
          renderWrapper={(children) => (
            <ScrollableContent ref={scrollElementRef} hasNoContentSpacing>
              {children}
            </ScrollableContent>
          )}
        >
          <div css={styles.controlsContainer(theme, isInModal)}>
            {isOutwardDateVisible && (
              <div css={styles.controlsRow}>
                <div css={styles.controlInputDate}>
                  <ControlInputDate
                    autoComplete="off"
                    disabled={mode === 'INWARD'}
                    name="startDate"
                    label={startDateLabel || formatMessage({ id: 'datepicker_outward_label' })}
                    rules={startDateFormRules}
                  />
                </div>
                {!hasTimeHidden && showStartDateHour && (
                  <div css={styles.controlDropdown}>
                    <ControlDropdown
                      disabled={mode === 'INWARD'}
                      id="startDateTime"
                      name="startDateTime"
                      options={startHoursOptions}
                      inputProps={{
                        'data-test': 'select-startDateTime',
                        'aria-label': `${formatMessage({ id: 'trip_outwardTimeLabel' })}, ${getOptionLabel(
                          startHoursOptions,
                          getValues('startDateTime')
                        )}`,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {isInwardDateVisible && (
              <div css={styles.controlsRow}>
                <div css={styles.controlInputDate}>
                  <ControlInputDate
                    buttonAriaLabel={formatMessage({ id: 'input_clearButton_inward_ariaLabel' })}
                    disabled={mode === 'OUTWARD'}
                    autoComplete="off"
                    label={initEndDate?.inputDateLabel || formatMessage({ id: 'datepicker_inward_label' })}
                    name="endDate"
                    isEndAdornmentHidden={mode === 'OUTWARD'}
                    onClear={onClearEndDate}
                    rules={endDateFormRules}
                  />
                </div>
                {!hasTimeHidden && showEndDateHour && endHoursOptions.length > 0 && (
                  <div css={styles.controlDropdown}>
                    <ControlDropdown
                      id="endDateTime"
                      name="endDateTime"
                      options={endHoursOptions}
                      disabled={mode === 'OUTWARD'}
                      inputProps={{
                        'data-test': 'select-endDateTime',
                        'aria-label': `${formatMessage({ id: 'trip_inwardTimeLabel' })}, ${getOptionLabel(
                          endHoursOptions,
                          getValues('endDateTime')
                        )}`,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div css={styles.datePickerContainer(theme)}>
            <ControlDatePicker
              scrollElementRef={scrollElementRef}
              control={control}
              initTarget={initTarget}
              maxDate={datePickerMaxDate}
              mode={mode}
              numberOfMonths={differenceInCalendarMonths(maxDate, new Date()) + 1}
              onChange={handleDatePickerChange}
              restrictedDatesList={restrictedDatesList}
            />
          </div>
        </ConditionalWrapper>
        <LargeUp>
          <Separator size="extraSmall" isNotOutline />
          <div css={styles.buttonWrapper}>
            <Button type="submit">
              <FormattedMessage id="validate" />
            </Button>
          </div>
        </LargeUp>
        <LargeDown>
          <BottomActions primary={{ type: 'submit', children: <FormattedMessage id="validate" /> }} />
        </LargeDown>
      </form>
    </FormProvider>
  )
}

export default LongDistanceDatePickerForm
