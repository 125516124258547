import { FC, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Dialog } from '@mui/material'
import { Theme, ThemeProvider, useTheme } from '@mui/material/styles'

import Modal from '@DS/components/navigation/modal/Modal'
import ModalPage from '@DS/components/navigation/modal/ModalPage'
import VisuallyHiddenLabel from '@DS/components/utils/aria/VisuallyHiddenLabel'
import ConditionalWrapper from '@DS/components/utils/wrapper/ConditionalWrapper'
import { UseModalStateReturn } from '@Hooks/useModal'
import { useIsLargeUp } from '@Hooks/useResponsive'

import LongDistanceDatePickerForm, { LongDistanceDatePickerFormProps } from './LongDistanceDatePickerForm'
import * as styles from './styles'

export type LongDistanceDatePickerWrapperProps = LongDistanceDatePickerFormProps & {
  datePickerOverrideTheme?: Theme
  modalProps: UseModalStateReturn['modalProps']
  visuallyHiddenLabelId?: string
  isInModal?: boolean
}

const LongDistanceDatePickerWrapper: FC<LongDistanceDatePickerWrapperProps> = ({
  datePickerOverrideTheme,
  modalProps,
  visuallyHiddenLabelId,
  mode,
  isInModal = false,
  ...datepickerFormProps
}) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  const isLargeUp = useIsLargeUp(theme)

  const dialogRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dialogRef.current?.focus()
  }, [])

  const getDisabilityText = () => {
    if (visuallyHiddenLabelId) {
      return visuallyHiddenLabelId
    }

    switch (mode) {
      case 'INWARD':
        return 'datepicker_inward_visuallyHiddenLabel'
      case 'OUTWARD':
        return 'datepicker_outward_visuallyHiddenLabel'
      default:
        return 'datepicker_roundTrip_visuallyHiddenLabel'
    }
  }

  return (
    <ThemeProvider theme={datePickerOverrideTheme || theme}>
      <ConditionalWrapper
        condition={isLargeUp && !isInModal}
        renderWrapper={(children) => (
          <Dialog
            fullWidth
            open={modalProps.isOpen}
            onClose={modalProps.onClose}
            aria-labelledby="longDistanceDatePickerWrapperDescriptionLabel"
            PaperProps={{ style: styles.longDistanceDatePickerDialog }}
          >
            <div ref={dialogRef} tabIndex={-1}>
              {children}
            </div>
          </Dialog>
        )}
        renderDefaultWrapper={(children) => (
          <Modal {...modalProps}>
            <ModalPage headerTitle={formatMessage({ id: 'datepicker_mobile_title' })}>{children}</ModalPage>
          </Modal>
        )}
      >
        <VisuallyHiddenLabel
          id="longDistanceDatePickerWrapperDescriptionLabel"
          label={formatMessage({ id: getDisabilityText() })}
        />
        <LongDistanceDatePickerForm mode={mode} {...datepickerFormProps} />
      </ConditionalWrapper>
    </ThemeProvider>
  )
}

export default LongDistanceDatePickerWrapper
